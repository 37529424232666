import {ApplicationConstants} from "./constants.class";
import FrontContextInterface from "../interfaces/front-context.interface";
import ProductListServiceInterface from "../interfaces/services/product-list-service.interface";
import ImageIntersectionServiceInterface from "../interfaces/services/image-intersection-service.interface";

export class FrontContextAccessor {
    private static get frontContext(): FrontContextInterface {
        return window[ApplicationConstants.FRONT_CONTEXT_WINDOW_IDENTIFIER];
    }

    public static get productListService(): ProductListServiceInterface {
        return this.frontContext.productListService;
    }
    public static initProductListServiceIfRequired(): Promise<void> {
        return this.frontContext.initProductListServiceIfRequired();
    }

    public static get imageIntersectionService(): ImageIntersectionServiceInterface {
        return this.frontContext.imageIntersectionService;
    }
}