import {SolexTranslationLocation} from "../enums/solex-translation-location.enum";
import {SolexLocalStorage} from "../classes/solex-local-storage.class";
import {SolexContextAccessor} from "../classes/solex-context-accessor.class";

export class SolexTranslationsHelper {
    private static TRANSLATIONS_WINDOW_IDENTIFIER = "slang";
    
    public static loadTranslations(){
        return new Promise<void>((resolve) => {
            window[this.TRANSLATIONS_WINDOW_IDENTIFIER] = {};
            
            if(this.hasLoadedTranslationsInCurrentLanguage()){
                return resolve();
            }

            import(`../services/solex-${ this.shouldLoadAdminTranslations() ? "admin" : "front" }-translations.service`).then(solexTranslationServiceModule => {
                new solexTranslationServiceModule.default().getTranslations().then(() => resolve());
            });
        });
    }

    private static shouldLoadAdminTranslations(){
        return window.location.href.toLocaleLowerCase().indexOf("adminbeta") > -1;
    }
    
    private static generateBaseLsKey() {
        return `lang_${ this.shouldLoadAdminTranslations() ? "a_" : "" }${ SolexContextAccessor.appParams.langId }`;
    };

    public static generateTranslationKey(translationLocation: SolexTranslationLocation) {
        return `${this.generateBaseLsKey()}_${translationLocation}`;
    }

    public static hasLoadedTranslationsInCurrentLanguage() {
        return SolexLocalStorage.getItem<boolean>(this.generateBaseLsKey()) == true;
    }

    public static afterLoadedTranslations() {
        SolexLocalStorage.setItem(this.generateBaseLsKey(), true);
    }

    public static setTranslation<T>(translationLocation: SolexTranslationLocation, obj: T) {
        SolexLocalStorage.setItem(this.generateTranslationKey(translationLocation), obj);
    }

    public static getTranslation<T>(translationLocation: SolexTranslationLocation) {
        return window[this.TRANSLATIONS_WINDOW_IDENTIFIER][+translationLocation] ??= SolexLocalStorage.getItem<T>(this.generateTranslationKey(translationLocation));
    }
}