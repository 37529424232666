import {ApplicationConstants} from "./constants.class";
import SolexContextInterface from "../interfaces/solex-context.interface";
import FetchServiceInterface from "../interfaces/services/fetch-service.interface";
import UiServiceInterface from "../interfaces/services/ui-service.interface";
import {SolexAppParameters} from "./solex-app-parameters.class";
import ModalServiceInterface from "../interfaces/services/modal-service.interface";
import FormValidationServiceInterface from "../interfaces/services/form-validation-service.interface";
import ToastNotificationServiceInterface from "../interfaces/services/toast-notification-service.interface";
import SSelectServiceInterface from "../interfaces/services/sselect-service.interface";

export class SolexContextAccessor {
    private static get solexContext(): SolexContextInterface {
        return window[ApplicationConstants.SOLEX_CONTEXT_WINDOW_IDENTIFIER];
    }
    
    public static get appParams(): SolexAppParameters {
        return this.solexContext.appParams;
    }
    
    public static get ui(): UiServiceInterface {
        return this.solexContext.ui;
    }
    
    public static get fetch(): FetchServiceInterface {
        return this.solexContext.fetch;
    }
    
    public static getModalService(): Promise<ModalServiceInterface> {
        return this.solexContext.getModalService();
    }
    
    public static getSSelectService(): Promise<SSelectServiceInterface> {
        return this.solexContext.getSSelectService();
    }
    
    public static getFormValidationService(): Promise<FormValidationServiceInterface> {
        return this.solexContext.getFormValidationService();
    }
    
    public static getToastNotificationService(): Promise<ToastNotificationServiceInterface> {
        return this.solexContext.getToastNotificationService();
    }
}