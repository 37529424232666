export class ApplicationConstants {
    static SOLEX_APP_WINDOW_IDENTIFIER = "sapp";
    
    static IMAGE_INTERSECTION_WINDOW_IDENTIFIER = "imageIntersection";
    
    static COMPONENT_CART_IMPORT_ATTRIBUTE = "data-s-import-pozycji";
    
    static EVENT_RELOAD_ALL_CART_SUMMARIES = "reload-cart-summaries";
    static EVENT_CART_LOADED = "cart-loaded";
    static EVENT_INDIVIDUALISATION_MODAL_SHOW = "individualisation-modal-show";
    
    static COMPONENT_PRODUCT_LIST_CLASS = ".kontrolka-ListaProduktow";
    static COMPONENT_PRODUCT_LIST_PERMANENT_FILTERS_CLASS = ".kontrolka-FiltryNaStronie";
    static COMPONENT_PRODUCT_LIST_SEARCH_CLASS = ".kontrolka-Wyszukiwarka";
    static COMPONENT_PRODUCT_LIST_CATEGORIES_CLASS = ".kontrolka-DrzewoKategorii";

    static DOCUMENT_READY_EVENT = "solex-doc-ready";
    
    static SOLEX_CONTEXT_WINDOW_IDENTIFIER = "sctxt";
    static FRONT_CONTEXT_WINDOW_IDENTIFIER = "fctxt";
    static FRONT_LOGGED_USER_CONTEXT_WINDOW_IDENTIFIER = "fluctxt";

    static MODAL_SERVICE_LINK_EVENT_BINDED_ATTRIBUTE = "data-s-m-l-l";
    static MODAL_SERVICE_LINK_SELECTOR = `a[href$="/m"]:not([${ ApplicationConstants.MODAL_SERVICE_LINK_EVENT_BINDED_ATTRIBUTE }]), 
                                            a[href$="modal=true"]:not([${ ApplicationConstants.MODAL_SERVICE_LINK_EVENT_BINDED_ATTRIBUTE }]), 
                                            a[data-modal="True"]:not([${ ApplicationConstants.MODAL_SERVICE_LINK_EVENT_BINDED_ATTRIBUTE }]),
                                            a[data-modal="true"]:not([${ ApplicationConstants.MODAL_SERVICE_LINK_EVENT_BINDED_ATTRIBUTE }])`;
    static MODAL_SERVICE_HIDE_ALL_MODALS_WITH_CUSTOM_CLASS_EVENT = "data-s-m-h-a";
    
    static MODAL_COMPONENT_SHOW_EVENT = "data-s-m-s";
    static MODAL_COMPONENT_HIDE_EVENT = "data-s-m-h";
    static MODAL_COMPONENT_SCROLL_TO_ATTRIBUTE = "data-s-m-s-t";
    static MODAL_COMPONENT_CONFIRM_BUTTON_ATTRIBUTE = "data-s-m-c";
    static MODAL_COMPONENT_CONFIRM_BUTTON_SELECTOR = `[${ ApplicationConstants.MODAL_COMPONENT_CONFIRM_BUTTON_ATTRIBUTE }]`;
    
    static USER_COMPLAINT_COMPONENT_ATTRIBUTE = "data-s-u-c";
    static USER_COMPLAINT_COMPONENT_SELECTOR = `[${ ApplicationConstants.USER_COMPLAINT_COMPONENT_ATTRIBUTE }]`;
    
    static PRISTINE_VALIDATE_SINGLE_FIELD_EVENT_NAME = "pristine-validate-single-field";
    static PRISTINE_VALIDATE_FORM_EVENT_NAME = "pristine-validate-form";
    
    static EVENT_SIMPLE_DATATABLES_RELOADED = "table-reloaded";
}