export default class ScriptImportHelper {
    private readonly basePath = "/Static/vendor";

    private readonly bundleNames: { [key: string]: string } = {
        "tinymce": `/tinymce/tinymce.min.js`,
        "ace": `/ace/ace.js`,
        "pristine": `/pristine/pristine.min.js`,
        "simple-datatables": `/simple-datatables/simple-datatables.min.js`,
        "slick": `/slick/slick.min.js`,
        "imagesloaded": `/imagesloaded/imagesloaded.min.js`,
        "masonry": `/masonry/masonry.min.js`,
        "bootstrap-slider": `/bootstrap-slider/bootstrap-slider.min.js`,
        "jquery": `/jquery/jquery.min.js`,
        "mark": `/mark/mark.min.js`,
        "google-translate": `https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`
    }
    
    private readonly bundleName: string;
    
    constructor(bundleName: string) {
        this.bundleName = bundleName;    
    }
    
    public load(): Promise<void> {
        const scriptUrl = this.bundleNames[this.bundleName];
        const bundleAttributeName = "data-js-bundle";
        
        if(!scriptUrl){
            alert(`Nie odnaleziono bundle o nazwie: ${ this.bundleName }`);
            return;
        }

        const scriptLoadedClass = "script-loaded";
        let scriptTag: HTMLScriptElement = document.querySelector(`[${ bundleAttributeName }="${ this.bundleName }"].${ scriptLoadedClass }`);

        return new Promise<void>(resolve => {
            const scriptLoadedEvent = "solex-script-loaded";

            if(scriptTag == null){
                scriptTag = document.createElement("script");
                scriptTag.setAttribute(bundleAttributeName, this.bundleName);
                scriptTag.src = `${ scriptUrl.indexOf("http") == -1 ? this.basePath : "" }${ scriptUrl }`;
                scriptTag.onload = () => {
                    scriptTag.classList.add(scriptLoadedClass);
                    scriptTag.dispatchEvent(new CustomEvent(scriptLoadedEvent));
                    resolve();
                };
                
                document.body.append(scriptTag);
                return;
            }
            
            if(scriptTag.classList.contains(scriptLoadedClass)){
                return resolve();
            }

            scriptTag.addEventListener(scriptLoadedEvent, () => resolve());
        });
    }
}