import "../../styles/front/unlogged_user/unlogged_user_app.scss";
import {SolexApp} from "./solex.app";
import {ApplicationConstants} from "../classes/constants.class";
import SolexControlInterface from "../interfaces/solex-control.interface";
import {SolexContextAccessor} from "../classes/solex-context-accessor.class";
import ScriptImportHelper from "../helpers/script-import.helper";
import {FrontContextAccessor} from "../classes/front-context-accessor.class";

export class SolexUnloggedUserApp extends SolexApp {
    static init(): Promise<void> {
        if (!document.body.classList.contains("BezLogowania")) return;

        return new SolexUnloggedUserApp()
            .initAppForUnloggedUser()
        // .catch(() => alert("Nie można uruchomić platformy. Skontaktuj się ze swoim przedstawicielem."));
    }

    // elementy strony
    public _pageHeader: HTMLDivElement;
    private _toTopButton: HTMLButtonElement;

    constructor() {
        super();
    }

    private readonly UNLOGGED_USER_CONTROLS: { [key: string]: () => Promise<{ readonly default: any }> } = {
        ".kontrolka-HamburgerMenu": () => import("../controls/hamburger-menu.control"),
        ".kontrolka-KolumnoweMenu": () => import("../controls/column-menu.control"),
        ".kontrolka-KlasyczneMenu": () => import("../controls/classic-menu.control"),
        ".kontrolka-GaleriaZdjecProduktu_Slajder": () => import("../controls/product-gallery-slick-slider.control"),
        ".kontrolka-GaleriaZdjecProduktuMiniatury": () => import("../controls/product-gallery-with-thumbnails.control"),
        ".kontrolka-WszystkieAtrybutyProduktu": () => import("../controls/product-attributes.control"),
        ".kontrolka-Rejestracja": () => import("../controls/register-form.control"),
        ".kontrolka-Formularz": () => import("../controls/user-defined-form.control"),
        ".kontrolka-PodgladFormularzaWypelnionego": () => import("../controls/user-defined-filled-form.control"),
        ".kontrolka-Blog": () => import("../controls/blog-entries-list.control"),
        ".kontrolka-AkceptacjaCiasteczek": () => import("../controls/cookies-bar.control"),
        ".kontrolka-GaleriaMasonry": () => import("../controls/gallery-masonry.control"),
        ".kontrolka-TranslatorGoogle": () => import("../controls/google-translate.control"),
        ".kontrolka-ZmianaJezyka": () => import("../controls/language-switch.control"),
    }

    public bootAppForUnloggedUser(): Promise<void> {
        return this.boot()
                    .then(() => this.initFrontContext())
                    .then(() => this.renderToastNotifications())
                    .then(() => {
                        SolexContextAccessor.ui.addUiTrigger((element: HTMLElement | Document) => FrontContextAccessor.imageIntersectionService.addImageToIntersectionObserver(element));
                        SolexContextAccessor.ui.addUiTrigger((element: HTMLElement | Document) => this.initPasswordValidationComponent(element));
                        SolexContextAccessor.ui.addUiTrigger((element: HTMLElement | Document) => this.initUserAddressComponent(element));
                        SolexContextAccessor.ui.addUiTrigger((element: HTMLElement | Document) => this.loadSolexControls(element, this.UNLOGGED_USER_CONTROLS));
                        SolexContextAccessor.ui.addUiTrigger((element: HTMLElement | Document) => this.toggleImageSrcChangeOnHover(element));
                        SolexContextAccessor.ui.addUiTrigger((element: HTMLElement | Document) => this.initShowDropdownOnHover(element));
                        SolexContextAccessor.ui.addUiTrigger((element: HTMLElement | Document) => this.createScrollableDivs(element));
                        SolexContextAccessor.ui.addUiTrigger((element: HTMLElement | Document) => this.initSlickSlider(element));
                        SolexContextAccessor.ui.addUiTrigger((element: HTMLElement | Document) => this.initSolexLightboxes(element));
                        SolexContextAccessor.ui.addUiTrigger((element: HTMLElement | Document) => this.initPrintDivs(element));
            
                        SolexContextAccessor.ui.addUiTriggerAfterAjax((element: HTMLElement | Document) => this.initReCaptcha(element));
            
                        SolexContextAccessor.ui.addFnAfterWindowResize(() => this.toggleFixedHeader());
                    });
    }

    // Metoda, która wykonuje się po załadowaniu strony
    public initAppForUnloggedUser() {
        return this.bootAppForUnloggedUser().then(() => {
            this.onDocReady();
            FrontContextAccessor.initProductListServiceIfRequired();
            SolexContextAccessor.ui.triggerUiEffects(document);
            SolexContextAccessor.ui.toggleFullScreenPreloader(false);
        });
    }

    //#region Metody protected
    // Metody, które powinny wykonać się tylko raz (on doc ready)
    protected onDocReady() {
        this._pageHeader = document.getElementById("page-header") as HTMLDivElement;
        this._toTopButton = document.getElementById("toTop") as HTMLButtonElement;

        this.toggleFixedHeader();
        this.toggleScrollToTopButtonClick();
        this.showModalNotificationIfAvailable();

        let resizeDelay: number;
        window.onresize = () => {
            clearTimeout(resizeDelay);
            resizeDelay = setTimeout(() => SolexContextAccessor.ui.triggerFnAfterWindowResize(), 50);
        }

        document.dispatchEvent(new Event(ApplicationConstants.DOCUMENT_READY_EVENT));
    }

    // Metoda dynamicznie ładująca kontrolki zdefiniowane przez użytkownika na froncie 
    // (na poziomie działania aplikacji w przeglądarce)
    protected loadSolexControls(element: HTMLElement | Document, controlsCollection: { [key: string]: () => Promise<{ readonly default: any }> }) {
        Object.keys(controlsCollection).forEach(key => {
            const controlContainers: HTMLElement[] = [].slice.call(element.querySelectorAll(key));

            if (!controlContainers.length) {
                return;
            }

            return controlsCollection[key]().then(controlExportedDefault => {
                controlContainers.forEach((controlContainer: HTMLDivElement) => (<SolexControlInterface>new controlExportedDefault.default()).initControl(controlContainer))
            });
        });
    }

    //#endregion

    //#region Metody prywatne
        //#region Metody, które mają się wykonać po zmianie szerokości ekranu
            private createScrollableDivs(element: HTMLElement | Document) {
                const scrollableDivs = [].slice.call(element.querySelectorAll(".przepelnienie"));
                scrollableDivs.map((scrollableDiv: HTMLDivElement) => scrollableDiv.style.height = `calc(100vh - ${scrollableDiv.offsetTop}px)`);
            }
        //#endregion

    //#region Metody dla przycisku "przewiń do góry"
    private toggleScrollToTopButtonClick() {
        this._toTopButton.addEventListener("click", () => {
            window.scroll({top: 0, behavior: "smooth"});
        });
    }

    private toggleFixedHeader() {
        if (this._pageHeader == null) return;
        import("../components/fixed-navbar.component").then((fixedNavbarObj) => new fixedNavbarObj.default(this._pageHeader, this._toTopButton));
    }

    //#region Metody inicjalizujące różne biblioteki
    // Inicjalizacja slick slidera
    private initSlickSlider(element: HTMLElement | Document) {
        const slickSliders = [].slice.call(element.querySelectorAll(`div:not(.slick-initialized)[data-slick]`));

        if (!slickSliders.length) {
            return;
        }

        new ScriptImportHelper("jquery").load().then(() => new ScriptImportHelper("slick").load().then(() => {
            slickSliders.map((slickSlider: HTMLElement) => {
                FrontContextAccessor.imageIntersectionService.addImageToIntersectionObserver(slickSlider);

                setTimeout(() => {
                    const slickConfig = JSON.parse(slickSlider.getAttribute("data-slick"));
                    const slickSlider$ = $(slickSlider);
                    slickSlider$.slick(slickConfig);
                }, 50);
            });
        }));
    }

    //#endregion

    // Metoda podpinająca delegaty pod obrazki, które po hoverze mają mieć załadowany nowy obrazek
    private toggleImageSrcChangeOnHover(element: HTMLElement | Document) {
        const imagesToChange = [].slice.call(element.querySelectorAll('[data-hover][data-src]'));

        imagesToChange.map((imageToChange: HTMLImageElement) => {
            imageToChange.addEventListener("mouseenter", () => imageToChange.setAttribute("src", imageToChange.getAttribute("data-hover")));
            imageToChange.addEventListener("mouseleave", () => imageToChange.setAttribute("src", imageToChange.getAttribute("data-src")));
        })
    }

    // Pokazywanie dropdowna po najechaniu myszką
    private initShowDropdownOnHover(element: HTMLElement | Document) {
        const dropdownHideTimeout = 500;
        const dropdownToggleEls = [].slice.call(element.querySelectorAll(".dropdown:not(.dropdown-click)"));

        dropdownToggleEls.map((dropdownToggleEl: HTMLElement) => {
            const dropdownMenu = dropdownToggleEl.querySelector(".dropdown-menu");
            if(!dropdownMenu){
                return;
            }
            
            let dropdownShowDelay: number;

            dropdownToggleEl.addEventListener("mouseenter", () => {
                clearTimeout(dropdownShowDelay);
                dropdownMenu.classList.add("show");
            });

            dropdownToggleEl.addEventListener("mouseleave", () => dropdownShowDelay = setTimeout(() => dropdownMenu.classList.remove("show"), dropdownHideTimeout));
        });
    }

    private initReCaptcha(element: HTMLElement | Document) {
        if (SolexContextAccessor.appParams.isLoggedIn || !SolexContextAccessor.appParams.recaptchaSiteKey) return;
        import("../components/recaptcha.component").then(reCaptchaComponentModule => new reCaptchaComponentModule.default(SolexContextAccessor.appParams.recaptchaSiteKey, element));
    }
    
    //#endregion

    //#region Powiadomienia
        private showModalNotificationIfAvailable() {
            const modalNotificationUrl = SolexContextAccessor.appParams.modalNotificationUrl;
    
            if (modalNotificationUrl?.length == 0) {
                return;
            }
    
            import("../components/custom-user-notification-modal.component").then(customUserNotificationModalComponent => SolexContextAccessor.getModalService().then(x => x.openModal(new customUserNotificationModalComponent.default(modalNotificationUrl))));
        }
    
        private renderToastNotifications(){
            const params = SolexContextAccessor.appParams;
    
            if(!params.toastNotifications) return;
    
            return SolexContextAccessor.getToastNotificationService().then(toastNotificationService => toastNotificationService.renderNotifications(params.toastNotifications));
        }
    //#endregion

    private initPasswordValidationComponent(element: HTMLElement | Document) {
        const inputsToValidatePassword = [].slice.call(element.querySelectorAll('input[type="password"][data-s-p-v]'));

        if (!inputsToValidatePassword.length) {
            return;
        }

        import("../components/password-validation.component").then(passwordValidationObj => new passwordValidationObj.default(element));
    }

    private initUserAddressComponent(container: Document | HTMLElement) {
        const addressComponentEls = container.querySelectorAll("[data-s-address-validation-country]");

        if (!addressComponentEls.length) {
            return;
        }

        import("../components/user-address.component").then(userAddressComponentObj => addressComponentEls.forEach((el: HTMLSelectElement) => new userAddressComponentObj.default(el)));
    }

    private initSolexLightboxes(container: Document | HTMLElement) {
        const solexLightboxToggleEls = (<HTMLAnchorElement[]>[].slice.call(container.querySelectorAll(`[data-s-lightbox]`))).map(x => x.getAttribute("data-s-lightbox") || undefined).filter(x => x !== undefined);

        if (!solexLightboxToggleEls.length) {
            return;
        }

        import("../components/solex-lightbox.component").then(lightboxComponentObj =>
            solexLightboxToggleEls.forEach(x => new lightboxComponentObj.SolexLightboxComponent({
                title: "&nbsp;",
                gallerySourceContainer: container as HTMLElement,
                gallerySelector: x
            })))

    }

    private initPrintDivs(container: Document | HTMLElement) {
        const printDivAttribute = "data-s-print-div";

        const printDivToggleEls = (<HTMLAnchorElement[]>[].slice.call(container.querySelectorAll(`[${printDivAttribute}]`)));

        if (!printDivToggleEls.length) {
            return;
        }

        printDivToggleEls.forEach(printDivToggleEl =>
            printDivToggleEl.addEventListener("click", (ev) => {
                ev.preventDefault();

                import("../components/print-div.component").then(printDivComponent => new printDivComponent.default(document.querySelector(printDivToggleEl.getAttribute(printDivAttribute))))

                return false;
            })
        );
    }
    
    protected initFrontContext(){
        return import("../classes/front-context.class").then(frontContextModule => new frontContextModule.default().initFrontContext());
    }
}

document.addEventListener('DOMContentLoaded', () => SolexUnloggedUserApp.init());