export class SolexLocalStorage {
    private static lsPrefix = "solex";
    
    // Klucz do czyszczenia localstorage
    private static lsCacheRefreshKey = "__c";
    
    static validateCache(cacheKey: string){
        if(this.getItem(this.lsCacheRefreshKey) == cacheKey) {
            return;
        }
        
        window.localStorage.clear();
        this.setItem(this.lsCacheRefreshKey, cacheKey);
    }
    
    static setItem<T>(key: string, obj: T){
        window.localStorage.setItem(this.generateLocalStorageIdentifier(key), JSON.stringify(obj));
    }
    
    static getItem<T>(key: string){
        return JSON.parse(window.localStorage.getItem(this.generateLocalStorageIdentifier(key))) as T;
    }
    
    private static generateLocalStorageIdentifier(key: string){
        return `${this.lsPrefix}_${key}`;
    }
}
