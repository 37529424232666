import "../../styles/bs5_app.scss";
import {SolexTranslationsHelper} from "../helpers/solex-translations.helper";

window.onunload = () => {}
window.onerror = (msg, url, line, col, error) => {
    const message = `${ msg }` + (error != null ? `<br /><br />${error.name}: ${error.message}<br />${error.stack}` : "");
    
    console.error(`--solexapperror--`);
    console.error(message);
    console.error(`--solexapperror--`);
    
    // import("../components/js-exception-modal.component").then(exceptionModalObj => new exceptionModalObj.default(message));
    //alert(message);
    
    return false;
};

export class SolexApp {
    constructor() {}

    protected boot() {
        this.enableMutationObserverForDynamicImportedStyles();
        return import("../classes/solex-context.class")
            .then(solexContextModule => new solexContextModule.default().createInstance())
            .then(() => this.loadTranslations());
    }
    
    //#region Metody prywatne
        // Ze względu na to, że stosujemy dynamic import styli css/scss w systemie, musimy umieszczać doładowane style PRZED stylami platformy, żeby mozna było je nadpisywać customowymi stylami css
        // W tym celu powstała ta metoda
        private enableMutationObserverForDynamicImportedStyles(){
            const linkElementToPrependStylesTo = document.head.querySelector("link");
            const mutationObserver = new MutationObserver((mutations) => this.onHeadMutation(mutations, linkElementToPrependStylesTo));
            mutationObserver.observe(document.head, { childList: true });
        }
        
        private onHeadMutation(mutations: MutationRecord[], linkElementToPrependStylesTo: HTMLLinkElement){
            const dynamicImportStylesheetAttribute = "data-s-di-stylesheet";

            mutations.forEach(mutationRecord => {
                const appendedLinkNodes: HTMLLinkElement[] = [].filter.call(mutationRecord.addedNodes, (el: HTMLElement) => el.tagName == "LINK" && (<HTMLLinkElement>el).href.indexOf("/bundles/") > -1 && !el.hasAttribute(dynamicImportStylesheetAttribute));
                
                if(appendedLinkNodes.length == 0){
                    return;
                }

                appendedLinkNodes.forEach(appendedLinkNode => {
                    appendedLinkNode.setAttribute(dynamicImportStylesheetAttribute, "");
                    linkElementToPrependStylesTo.insertAdjacentElement("beforebegin", appendedLinkNode);
                });
            });
        }
    //#endregion

    protected loadTranslations(){
        return SolexTranslationsHelper.loadTranslations();
    }
}